/*  */
@media (min-width:321px) and (max-width:768px) {
    user_nav,
    user_article {
        width: 100%;
        height: auto;
    }
}


@font-face {
    font-family: 'font-bold';
    src: url(../public/fonts/MYRIADPRO-BOLD.OTF);
}

@font-face {
    font-family: 'font-medium';
    src: url(../public/fonts/MYRIADPRO-SEMIBOLD.OTF);
}

@font-face {
    font-family: 'font-regular';
    src: url(../public/fonts/MYRIADPRO-REGULAR.OTF);
    
}

@font-face {
    font-family: 'font-light';
    src: url(../public/fonts/MyriadPro-Light.otf);
}

.container {
  width: 100%;
}

/* .progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 20%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height : 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: green;
}
.progressbar li.active + li:after {
  background-color: green;
} */

.navigation_menu {
  font-family: "Helvetica";
  font-size: 14px;
  margin-top: 5px;
}

.navigation_tabs {
  counter-reset: step;
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.navigation_tabs::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}

.navigation_menu ul {
  list-style-type: none;
  padding-right: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0;
}

.navigation_menu li {
  position: relative;
  counter-increment: list;
}

.navigation_menu li:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 16px;
  position: absolute;
  width: 10px;
  top: 32px;
  z-index: 9;
  border-left: 2px solid #ccc;
  margin-left: 3px;
}
.navigation_menu li:after {
  background-color: #fff;
  border-radius: 50%;
  counter-increment: step;
  display: block;
  height: 21px;
  left: 8px;
  line-height: 26px;
  margin: 0 auto 10px;
  position: absolute;
  top: 10px;
  z-index: 10;
  width: 25px;
}

.navigation_menu li:not(:last-child) {
  padding-bottom: 25px;
}

.navigation_menu li.tab_inactive:before {
  border-left: 2px solid #ff4339;
  margin-left: 3px;
}

.navigation_menu li.tab_active:after {
  border: 1px solid #ff4339;
}
.navigation_menu li.tab_inactive:after {
  content: "+";
  font-size: 20px;
  color: #ff4339;
  text-align: center;
  border: 1px solid #ff4339;
}

.navigation_tabs li p {
  display: block;
  padding-top: 0;
  text-decoration: none;
  padding-left: 40px;
}

.navigation_tabs li.tab_inactive p {
  color: #ff4339;
}

.navigation_tabs li.tab_disabled p {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.navigation_tabs li.tab_active p:hover,
.navigation_tabs li.tab_inactive p:hover {
  font-weight: bold;
}


.sign_btn {
    background: #FF4339 !important;
    border-radius: 100px !important;
    color: #ffffff !important;
    padding: 10px 15px !important;
    font-size: 1rem !important;
    transition: 0.5s !important;
    cursor: pointer !important;
    border: 0;
}
.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid;
  position: relative;
  border-color: #ff4339;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  padding-bottom: 2px;
}

.circle:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 45%;
  border: 1px solid;
  border-width: 0 0 0 2px;
  border-color: #ff4339;
  width: 1px;
  height: 47px;
}
.line {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 45%;
  border: 1px solid;
  border-width: 0 0 0 2px;
  border-color: #ff4339;
  width: 1px;
  height: 47px;
}

@font-face {
  font-family: "font-bold";
  src: url(../public/fonts/MYRIADPRO-BOLD.OTF);
}
@font-face {
  font-family: "font-medium";
  src: url(../public/fonts/MYRIADPRO-SEMIBOLD.OTF);
}
@font-face {
  font-family: "font-regular";
  src: url(../public/fonts/MYRIADPRO-REGULAR.OTF);
}
@font-face {
  font-family: "font-light";
  src: url(../public/fonts/MyriadPro-Light.otf);
}
@import "rsuite/dist/rsuite.css";
body::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f8fbff;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #9fa9bd;
  border-radius: 5px;
}
div::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 5px;
}
.css_profile {
  width: 100px;
  height: 100px;
}
.custom-calendar.rmdp-wrapper {
  background-color: #f1f2f4;
  border-radius: 16.4035px;
}

.rmdp-arrow-container:hover {
  background-color: #ff4339 !important;
}

.red .rmdp-panel-body li {
  background-color: #ff4339 !important;
}
.red .b-deselect,
.rmdp-header-values {
  color: #ff4339 !important;
}
.rmdp-arrow {
  margin-top: 7px !important;
}
.rmdp-down i {
  margin-top: 5px !important;
}
.rmdp-right i {
  margin-top: 7px !important;
}
.rmdp-shadow {
  box-shadow: 0 0 5px transparent !important;
}
.rmdp-day.rmdp-selected span:not(.highlight),
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #ff4339 !important;
}
.rmdp-day.rmdp-today span {
  background-color: #ff3939a6 !important ;
}
.rmdp-week-day {
  color: gray !important;
}
.rmdp-time-picker div input {
  box-sizing: border-box;
  width: 80.6px !important;
  height: 49px !important;
  left: 256px;
  top: 601px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 10px;
  font-size: 18px !important;
}
.rmdp-time-picker div .rmdp-am {
  box-sizing: border-box;
  width: 80.6px !important;
  height: 49px !important;
  left: 256px;
  top: 601px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 10px;
  margin-left: 5px;
  line-height: 40px !important;
  font-size: 18px !important;
}
.red .rmdp-arrow-container:hover {
  box-shadow: 3px 3px 5px var(--rmdp-secondary-red) !important;
}
.color-mtrz {
  color: #ff4339;
}
.cust_underline {
  border-bottom: 1px solid;
  width: 30px;
  color: #ff4339;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #ff4339;
  border-radius: 50%;
  display: inline-block;
}

.offline_button {
  margin-top: -55px;
}
.list-group {
  border-radius: 0px !important;
}

.img-offline {
  width: 60% !important;
  margin-top: -60px;
}
.badge_border {
  border: 1px solid #ff4339;
  transform: translate(-80%, -40%) !important;
  color: #ff4339;
}

.image-upload > input {
  display: none;
}
.profile_size {
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 50%;
}
.small_image {
  width: 35%;
}
.horizontal_line {
  border-top: 1px solid lightgray;
}
.verticle_line {
  color: rgb(250, 88, 79);
  width: 2px;
  height: 23px;
  opacity: unset;
  margin-top: 5px;
}
.verticle_line1 {
  color: transparent;
  width: 2px;
  height: 23px;
  opacity: unset;
  margin-top: 5px;
  border-left: 1px solid lightgray;
  height: 118vh;
}

.verticle_line2 {
  color: transparent;
  width: 2px;

  opacity: unset;
  margin-top: 5px;
  border-left: 1px solid lightgray;
}

*,
p {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body,
html {
  font-family: "font-regular";
  line-height: 1;
  background-color: #ffffff;
  color: #999999;
  /* background-image: url("../public/assets/images/BackgroundImage.png"); */
}
.cust-backgorund {
  font-family: "font-regular";
  line-height: 1;
  background-color: #ffffff;
  color: #999999;
  background-image: url("../public/assets/images/BackgroundImage.png");
  /* height: 100vh;  */
  padding: 3% 15% 3% 15%;
}

.sign-background {
  font-family: "font-regular";
  line-height: 1;
  background-color: #ffffff;
  color: #999999;
  background-image: url("../public/assets/images/BackgroundImage.png");
  height: 100vh;
  padding: 3% 12% 3% 12%;
}

.table td a {
  color: #ff4339;
}
.table td a:hover {
  color: #ff4339;
  text-decoration: underline;
}
.dropdown-menu a {
  color: #000000 !important;
  text-decoration: none !important;
}
.w-15 {
  width: 220px;
}
.w-10 {
  width: 100px;
}
.cursor-pointer {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.font-bold {
  font-family: "font-bold";
  font-weight: 700;
}
.font-medium {
  font-family: "font-medium";
  font-weight: 600;
}
.font-light {
  font-family: "font-light";
  font-weight: 300;
}
a {
  text-decoration: none;
}
.radius-14 {
  border-radius: 14px;
}
.bg-color {
  background-color: #f5f5f7;
}
.sidenav {
  height: calc(100vh - 2rem);
  background-color: #f5f5f7;
  border-radius: 1.5rem;
  padding: 1rem 0.875rem;
  overflow-y: auto;
}
.logo {
  width: 74px;
  height: 74px;
  border-radius: 100%;
  background-color: #c4c4c4;
}
.nav_name {
  font-size: 0.875rem;
  line-height: 2rem;
}
/* .nav_name{
  display: none;
}
.sidenav.show .nav_name{
  display: block;
} */

.submenu a {
  color: #999999;
}
.submenu a.active,
.submenu a:hover {
  color: #ff4339;
}

.header_toggle {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
}
.l-navbar {
  padding: 1rem 0.5rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  transition: 0.1s;
}
.navigation {
  /* height:  100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.nav_logo,
.nav_link {
  /* display:  grid; */
  display: block;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  /* padding:  .5rem 0 .5rem 1.5rem; */
  text-align: center;
}
.nav_link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.nav_logo {
  margin-bottom: 2rem;
}
.nav_logo-icon {
  font-size: 1.25rem;
  color: #ffffff;
}
.nav_logo-name {
  color: #ffffff;
  font-weight: 700;
}
.nav_link {
  position: relative;
  margin-bottom: 0.5rem;
  transition: 0.3s;
  color: #999999;
}
.nav_link:hover,
.nav_link.active {
  color: #ff4339;
}
.nav_icon {
  /* width: 30px; */
  font-size: 1.25rem;
}
.nav_icon img {
  width: auto;
}
.text-whiteh {
  word-break: normal !important;
  height: 40px;
}

.body-pd {
  padding-left: calc(110px + 1rem);
}

.height-100 {
  height: 100vh;
}

.dropdown-toggle::after {
  position: absolute;
  right: 0;
}
.nav-head {
  font-family: "font-medium";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}
.nav-mail {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main-top-section h1 {
  font-family: "font-bold";
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
}
.profile-img {
  width: 113px;
  height: 113px;
  background-color: #c4c4c4;
  border-radius: 100%;
}

/* Buttons */
.btn-app {
  background: #ff4339;
  border-radius: 100px;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
  transition: 0.5s;
  cursor: pointer;
}
.btn-app.btn-outline {
  background-color: #ffffff;
  border-color: #ff4339;
  color: #ff4339;
}
.btn-app.btn-outline:hover {
  background-color: #ff4339;
  color: #ffffff;
}
.btn-app:hover {
  color: #ffffff;
  background-color: #eb3127;
}

/*tabs*/
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus-visible,
.nav-tabs .nav-link {
  border-color: transparent;
  margin: 0;
}

/* forms */
.custom-form-group .form-control:hover,
.custom-form-group .form-control:focus {
  box-shadow: none;
}
.nav .nav-item .nav-link {
  color: 000000;
  font-size: 1.5rem;
  font-weight: 600;
}
.nav .nav-item .nav-link.active {
  color: #ff4339;
  background: none;
}
.chat-legend {
  display: flex;
  flex-direction: row;
}
.chat-legend li {
  color: #ff4339;
  font-size: 14px;
  position: relative;
  list-style: none;
}
.chat-legend li::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 100%;
  left: -20px;
  top: 0;
  background-color: transparent;
}
.chat-legend li.this-week::before {
  background-color: #484848;
}
.chat-legend li.last-week::before {
  background-color: #ff4339;
}
.list-actions .action-item {
  height: 36px;
  min-width: 70px;
  color: #999999;
}
.list-actions .action-item:hover {
  background-color: rgba(153, 153, 153, 0.15);
}
.search {
  background: rgba(153, 153, 153, 0.15);
  border-radius: 8px;
}
.form-control:focus,
.form-control:hover {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "font-light";
}
textarea.form-control {
  height: 94px;
  resize: none;
}
.feedback-message {
  height: 180px !important;
  border-radius: 1rem;
}
.feedback-message::placeholder {
  color: #c8cad5;
}
.table-profile-name {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  background: rgba(153, 153, 153, 0.2);
  color: #999999;
}
.table-profile-pic {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  border: 2px solid #dadada;
}

.table {
  color: #e5e7eb;
}
.table th {
  font-size: 0.75rem;
  font-family: "font-bold";
  font-weight: 700;
  color: #6b7280;
}
.table td {
  vertical-align: middle;
  color: #4b5563;
}

.add-picture {
  position: relative;
  cursor: pointer;
}
.add-picture {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  border: 3px solid rgba(153, 153, 153, 0.3);
  cursor: pointer;
}
.picture-label {
  font-size: 12px;
}
.upload-pic .file-upload-input {
  opacity: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 9;
  cursor: pointer;
  top: 38px;
  left: -14px;
}
.upload-pic {
  position: relative;
}
.upload-pic .file-upload-input + .file-upload-label {
  position: relative;
  font-size: 12px;
}
.upload-pic .file-upload-input + .file-upload-label::before {
  content: "+";
  position: absolute;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 100%;
  left: -14px;
  top: 30px;
  border: 2px solid rgba(153, 153, 153, 0.3);
  background-color: #ffffff;
}
.settings .title {
  font-size: 0.875rem;
  color: #404253;
  min-width: 100px;
}
.settings .form-check {
  min-width: 220px;
}
.form-check-input {
  width: 24px;
  height: 24px;
}
.form-check .form-check-label {
  font-size: 14px;
  color: #737373;
}
.form-check-input:checked {
  background-color: #ff4339;
  border-color: #ff4339;
}
.form-check-input:focus {
  box-shadow: none;
}
.form-check-input:checked + .form-check-label {
  color: #252b42;
}

.view-manage {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 1px 2px rgba(64, 66, 83, 0.1);
}
.view-manage .nav-tabs .nav-item .nav-link {
  padding: 2px 40px;
  min-width: 140px;
  text-align: center;
  border: 1px solid rgba(200, 202, 213, 0.75);
  border-radius: 100px;
  color: #000000;
  font-size: 0.875rem;
}
.view-manage .nav-tabs .nav-item .nav-link.active {
  background: rgba(255, 67, 57, 0.15);
  border-color: rgba(255, 67, 57, 0.75);
}

.slider-horizontal {
  width: 100% !important;
}
.slider-selection {
  background: #ff4339 !important;
}
.slider-handle {
  background-image: none !important;
  background: #ff4339 !important;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}
.commnet_box_footer {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.commnet_box_header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 7px 0px;
  height: 50px;
}
.commnet_box_footer_imag {
  width: 150%;
  border-radius: 100%;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #bdbdbd !important;
  text-align: center;
  background-color: transparent !important;
  border-radius: 4px;
}
.tooltip.top .tooltip-ar {
  display: none !important;
}
.slider .tooltip.top {
  margin-top: -25px !important;
}
.tooltip-min,
.tooltip-max {
  opacity: 1;
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  z-index: 99 !important;
}
.tooltip-inner {
  color: #fff !important;
}

.invites {
  background: #ffffff;
  border: 1.70356px solid #efeff4;
  border-radius: 36px;
  text-align: center;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
}
.invites .invite-img {
  width: 142px;
  height: 142px;
  border-radius: 100%;
  border: 2px solid #dadada;
}
.Weekday {
  color: #252b42;
  font-size: 0.875rem;
}
.beta {
  font-size: 28px;
  font-weight: 700;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 25px;
  color: #999999;
  padding: 8px 25px;
  text-transform: uppercase;
  line-height: 1;
}
.heading-section {
  font-size: 0.75rem;
}
.list-dropdown .dropdown-toggle::after {
  display: none;
}
.count {
  font-size: 64px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute !important;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 1px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: rgba(255, 255, 255, 0.7);
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(75px);
  -ms-transform: translateX(75px);
  transform: translateX(75px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.biiling-amount {
  font-weight: 700;
  font-size: 52px;
  line-height: 62px;
  color: #ff4339;
}
.bi-star {
  color: #f3cd47;
}

.tab-content {
  padding-top: 20px;
}
.responsive-tabs .nav-tabs {
  display: none;
}
.responsive-tabs .card .collapse,
.responsive-tabs .card {
  width: 100%;
}
.ring-table th,
.ring-table td {
  border: 0;
}
.ring-table td:first-child {
  border-radius: 16px 0 0 16px;
}
.ring-table td:last-child {
  border-radius: 0 16px 16px 0;
}
@media (min-width: 1025px) {
  .responsive-tabs .nav-tabs {
    display: flex;
  }
  .responsive-tabs .card {
    border: none;
  }
  .responsive-tabs .card .card-header {
    display: none;
  }
  .responsive-tabs .card .collapse {
    display: block;
  }
}
@media (max-width: 1024px) {
  .responsive-tabs .tab-pane {
    display: block !important;
    opacity: 1;
  }
  .responsive-tabs .tab-pane.show {
    width: 100%;
    left: 0;
  }
  .card-body {
    overflow: auto;
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 0 2rem 0 calc(110px + 2rem);
  }
  .header_img {
    width: 40px;
    height: 40px;
  }
  .header_img img {
    width: 45px;
  }
  /* .show {
  width:  calc(110px + 156px);
} */
  .body-pd {
    padding-left: calc(110px + 188px);
  }
}

code {
  color: #e01a76;
}
.justicecenter {
  justify-content: center;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.cust-button {
  background: #ff4339;
  width: 20%;
  line-height: 92%;
  border-radius: 36.2459px;
  color: white;
  font-size: 15px;
}
.image_css {
  border-radius: 15px;
  width: 100%;
}
.background_css {
  background: linear-gradient(
    180deg,
    #efeded 0%,
    rgba(255, 255, 255, 0) 50%,
    #efeded 100%
  );
}

.title_css {
  justify-content: center;
  font-weight: 700;
}
.text_center {
  justify-content: center;
  color: #404253;
  font-weight: 700;
  font-size: 25px;
}
.text-alignecss {
  text-align: center;
  color: #404253;
}
.ly1_css {
  padding: 0% 30% 0% 30%;
  text-align: center;
}
.ly2_css {
  padding: 0% 35% 0% 35%;
}
.profile_bg {
  background: #ffffff;
  border: 1.70356px solid #efeff4;
  border-radius: 36.2459px;
  padding: 2% 2% 2% 2%;
  /* margin-left: 10px; */
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}
.oval_button {
  background: #ff4339;
  border-radius: 36.2459px;
  color: #efeded;
  line-height: 13px;
  font-size: 14px;
  width: 150px;
}
.oval_button.outline_button {
  border: 1px solid #ff4339;
  background: white;
  color: #ff4339;
}
.oval_button.outline_button:hover {
  color: #ff4339;
}
.radio_size {
  width: 20px;
  height: 20px;
}
.bg_image {
  background-image: url("https://via.placeholder.com/500");
}

.main_login {
  border: 1px solid #c4c4c4;
}
.box- {
  background: #fafafa;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}
._button {
  border-radius: 33px;
  width: 60%;
  border: 1px gray solid;
}

.shadow_box {
  width: 182px;
  height: 139px;
  left: 413px;
  /* top: 480px; */
  background: #fafafa;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.Auth-form {
  /* width: 420px;*/
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
  /* width: 90%; */
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  margin-bottom: 1em;
  font-size: 24px;
  color: #4f4f4f;
  font-weight: 700;
}
.form-control {
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
.btn-mentorz {
  border-color: #ff4339;
  background: #ff4339;
  color: #fff;
}
.errorMsg {
  color: #ff4339;
  margin-bottom: 12px;
  font-size: 14px;
}

.slick-prev:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: red !important;
    -webkit-font-smoothing: antialiased;
}

.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: red !important;
  -webkit-font-smoothing: antialiased;
}
.slick-next, .slick-prev {
    background: none !important;
}

/* .innerslide{
    background-color: yellow;
  } */
/* the slides */

@media (min-width:321px) and (max-width:768px) {
    .slick-next {
        right: 8px;
    }
}

@media (min-width:321px) and (max-width:768px) {
    .list-unstyled {
        margin-left: 22%;
    }
}

.slick-slide {
  padding: 0 12px;
  box-sizing: border-box;
}
.signStyle {
  float: right;
  border: 1px solid #c4c4c4;
  margin-left: 14px;
  margin-left: 20px;
  margin-right: -43px;
  margin-bottom: 25px;
  color: #000000;
  /* padding: 5px 5px 5px 5px; */
  border-radius: 6px;
}

.logincss {
  margin-top: 3rem;
}
.btn-facebook {
  background: #1877f2 !important;
  color: white;
  border-radius: 50px;
}
.btn-linkdein {
  background: #0077b5 !important;
  color: white;
  border-radius: 50px;
}
.btn-apple {
  background: #000000 !important;
  color: white;
  border-radius: 50px;
}
.btn-SSO {
  background: #ff4339;
  color: white;
  border-radius: 50px;
  font-size: 21px;
  font-weight: 500;
  width: 50%;
}
input[type="submit"] {
  width: 30%;
  border-radius: 20px;
  background: #ff4339;
  color: #ffffff;
  height: 40px;
  border: 0;
}
.button-container {
  text-align: center;
  font-size: "20px";
  font-weight: "500";
}
.span_button {
  font-family: "font-bold";
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  /* margin: 5px 0px 0px 20px; */
}
.forgot_pass {
    left: 805px;
    top: 550px;
    font-family: "font-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ff4339;
}
.button-custompro {
  text-align: left;
}

.underline:before {
  content: "";
  display: block;
  width: 207px;
  height: 1px;
  background: #c4c4c4;
  position: absolute;
  margin-left: 4px;
  /* top: 67%; */
}
.underline:after {
  content: "";
  /* display:block; */
  width: 199px;
  height: 1px;
  background: #c4c4c4;
  position: absolute;
  margin-left: 15px;
  /* top: 67%; */
  /* right: 0;
    top: 50%;
    z-index: -2; */
}
.underlinesignup:before {
  content: "";
  display: block;
  width: 210px;
  height: 1px;
  background: #c4c4c4;
  position: absolute;
  margin-left: 79px;
  /* top: 67%; */
}
.underlinesignup:after {
  content: "";
  /* display:block; */
  width: 229px;
  height: 1px;
  background: #c4c4c4;
  position: absolute;
  margin-left: 4px;
  /* top: 67%; */
  /* right: 0;
    top: 50%;
    z-index: -2; */
}

.vector-image {
  padding-right: 24px;
}
.vector-images {
  padding-right: 7px;
}
.textcolor {
    color: #4958e0;
    font-family: "font-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.2px;
}
.button-customcss {
  margin-top: 2rem;
}
.forgot-password {
  letter-spacing: 0.8px;
  /* margin-left: 8px; */
}

.individualcss {
  margin-top: 8px;
  height: 130px;
  border-radius: 5px;
  text-align: center;
  background: whitesmoke;
  padding-top: 39px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #000000;
  font-weight: bold;
  margin: 7px;
  font-size: 19px;
}
.btn {
  color: white;
}
/* PROFILE CSS  */
.Profile-backgroundImg {
  height: 200px;
}

.ProfileNameCss {
  align-items: end;
  z-index: 1;
}
.profile-bg {
  background-color: #931e1e;
  background-image: url("../public/assets/images/Grou.png");
  height: 200px;
  position: absolute;
  left: 0;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profileCss {
  padding-bottom: 20px;
  justify-content: center;
}
.profileCssq {
  padding-bottom: 20px;
  display: flex;
  align-items: spa;
  justify-content: space-evenly;
}
.profilegapcss {
  padding-left: 15px;
  background-color: transparent;
}
.profileimgcss {
  position: absolute;
  top: 153px;
  /* left: 106px; */
}

.profileuserimgcss {
  width: 150px;
  height: 150px;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff4339;
}
.profileusernamecss {
  color: white;
}

.profileBorder {
  height: 38px;
  width: 10px;
  color: grren;
  border-left: solid 1px #ffffff;
  padding-left: 20px;
  color: #f1f2f4;
}
.socialbtn {
  display: flex;
  margin-top: 15px;
}
.btn-profile-fb {
  width: 40px;
  margin-left: 81px;
}
.btn-profile-lk {
  width: 40px;
}
.profilefollwer {
  padding-top: 6px;
}
.cusTextcss {
  border-radius: 5px;
  background: whitesmoke;
  height: 28px;
  width: 199px;
  padding-top: 2px;
}
.btn:hover {
  color: white;
  box-shadow: 0px 0px 2px 0px #fff;
}
.profileDesc {
  height: 150px;
  background-color: green;
}
.Profile-Container {
  background: #fffffe;
  box-shadow: 0px 4px 20px rgba(164, 164, 164, 0.25);
  border-radius: 20px;
}

.footerspace {
  margin-bottom: 200px;
}
.Profile-journey {
  background: #fffffe;
  box-shadow: 0px 4px 20px rgb(164 164 164 / 25%);
  border-radius: 20px;
  height: 47px;
  justify-content: center;
  padding: 5px 0px 0px 0px;
  cursor: pointer;
}
.Profile-name {
  /* font-family: 'Myriad Pro'; */
  font-style: normal;
  font-weight: 600;
  /* font-size: 21px; */
  /* line-height: 25px; */
  display: flex;
  align-items: center;
  /* text-align: center; */
  color: #404253;
}

html,
    body,
    a,
    h1,h2,h3,h4,h5,h6,
    select,
    fieldset,
    /* input, */
    button,
    select,
    textarea,
    optgroup,
    label,
    option {
  /* font-family: 'Myriad Pro'; */
  font-style: normal;
  font-weight: 600;
}

.profilecustomselect {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0px;
}
.profile-capsul {
  cursor: pointer;
  background-color: transparent;
  height: 30px;
  border-radius: 50px;
  border: 1px solid gray;
  text-align: center;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 13px;
  font-size: 14px;
}
.capsul {
  cursor: pointer;
  background-color: transparent;
  height: 30px;
  border-radius: 50px;
  color: #ff4339;
  border: 1px solid #ff4339;
  text-align: center;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 13px;
  font-size: 14px;
}
textarea {
  min-height: 154px;
  width: calc(100% - 12px);
  margin-top: 10px;
  resize: none;
  overflow: hidden;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  outline: none;
}

.profilecustomcss {
  text-align: center;
  color: #404253;
}
.profile-personal {
  color: #404253;
  margin-left: 8px;
}
.profile-role {
  margin-top: 35px;
  margin-left: 10px;
  color: #404253;
}
.profile-button {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}

.profile-radio {
  padding-left: 15px;
  margin-top: 7px;
}

.profile-cancel-btn {
  background: white;
  border-radius: 36.2459px;
  color: #ff4339;
  border: 2px solid #ff4339;
}

.profile-radiocustom {
  margin-left: 10px;
}
.profile-cancelbtn {
  text-align: end;
  margin-top: 30px;
  margin-bottom: 30px;
}
.profile-continue-btn {
  margin-top: 30px;
  margin-bottom: 30px;
}

.profile-inputcss {
  background-color: white;
  border-bottom: 1.78829px solid #a4a4a4;
  border-radius: 0;
}

.input_icon {
  background-color: white;
  border: none;
  margin-top: 6px;
  cursor: pointer;
  color: #c4c4c4;
}

.select-country {
  background-color: white;
  border: none;
  margin-top: 6px;
  cursor: pointer;
  color: #0000009c;
}

.form-select:focus {
  border-color: #ced4da !important;
  outline: 0 !important;
  box-shadow: none;
}
.css-1p3m7a8-multiValue {
  border-radius: 6px !important;
}

/* NAVBAR CSS */
.navbar {
  background-color: #ff4339 !important;
  padding-top: 23px;
  padding-bottom: 23px;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
}

.logo img {
  width: 50px; /* Adjust the size as needed */
}


.links {
    display: flex;
    gap: 30px;
    align-items: center;
    color: #101230;
}
.linkbtn {
  color: #101230;
}
.freebtn {
  background: #ff4339;
}
.freebtn:hover {
  background: #ff4339;
}

.nav-btn-link {
  color: #f1f2f4;
  font-size: 20px;
  margin: 12px 20px;
  text-decoration: none;
}
.nav-btn-link:hover {
  color: #f1f2f4;
  text-decoration: underline;
}
.nav-btn {
  font-size: 16px;
  border-radius: 40px;
}

.nav-search {
  position: relative;
}
.navlink-slide {
    background: white;
    font-size: 30px;
    margin-top: 15px;
}
.hero-btn {
  padding: 10px 52px;
  background: #ff4339;
  border-color: #ff4339;
  border-radius: 40px;
  color: #f1f2f4;
  font-size: 22px;
}

.btn-custom {
  background: #ff4339;
  border-color: #ff4339;
  border-radius: 40px;
  color: #f1f2f4;
  font-size: 22px;
  width: 100%;
  line-height: 25px;
}
.btn-custom:hover {
  background: white;
  border-color: #ff4339;
  color: #ff4339;
  width: 100%;
}

.title_fontsize {
  font-size: 13px;
}
.decstp_fontsize {
  font-size: 12px;
}
.title2_textalign {
  text-align: end;
}
.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 40px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;

  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: #ffffff;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #ff4339;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 16px;
  border-radius: 10px;
}
.horizontal-slider .example-thumb {
  top: 15px;
  width: 23px;
  outline: none;
  height: 23px;
  line-height: 21px;
  color: red;
  padding: 1px 0px 0px 7px;
  font-size: 14px;
}

/* For customer login */
.customLogin-Auth-form-content {
  padding-left: 22%;
  padding-right: 22%;
}

.actcss {
    float: right;
    margin-top: 16px;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    font-family: "font-regular";
    font-style: normal;
}

    @media (min-width:321px) and (max-width:768px) {
        .normal {
            margin-right: 0rem;
        }
        .actcss {
            margin-top: 16px;
            color: #000000;
            font-size: 18px;
            font-weight: 400;
            font-family: "font-regular";
            font-style: normal;
        }
        .half{
            width:100%;
        }
        .shadw{
            width:50%;
        }
    }
.normal {
    margin-right: 9rem;
}

    .custsignStyle {
        margin-right: 0rem;
        font-size: 18px;
        padding: 6px 8px 12px 8px;
        float: right;
        border: 1px solid black;
        margin-left: 2rem;
        color: #000000;
        border-radius: 6px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.8px;
        border-radius: 10px;
        margin-top: 10px;
    }

        .custsignStyle:hover {
            background-color: #f1f2f4;
            color: #404253;
        }

    .signupheading {
        color: #000000;
        font-weight: bold;
        text-align: right;
    }

    .Auth-form-titleCust {
        margin-bottom: 1em;
        font-size: 36px;
        color: #4f4f4f;
        font-weight: 700;
    }

    .btn_face {
        width: 90% !important;
        margin-left: 26px;
        height: 48px;
    }

    .btn_link {
        width: 90% !important;
        height: 48px;
    }

    /* ProfileConatiner */
    .title {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #404253;
        margin-top: 30px;
        font-family: "font-regular";
        margin-bottom: 60px;
    }

    .titlesecond {
        font-family: "font-regular";
        font-size: 24px;
        font-weight: 600;
        color: #404253;
    }

    .profiletitle3 {
        font-family: "font-regular";
        font-size: 16px;
        font-weight: 400;
        color: #404253;
    }

    .profilecancelcss {
        text-align: end;
    }

    .rolecss {
        margin-top: 50px;
        font-weight: 700;
        font-size: 18px;
        color: #404253;
    }

    .introcss {
        font-weight: 600;
        font-size: 36px;
        color: #404253;
    }

    .introjrn {
        color: #404253;
        font-weight: 400;
        font-size: 18px;
    }

    .circle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid;
        position: relative;
        border-color: #ff4339;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        top: 9px;
    }

    circle:last-of-type {
        background: red;
    }

    .circle_inactive {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid;
        position: relative;
        border-color: #6b7280;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

        .circle_inactive:before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 45%;
            border: 1px solid;
            border-width: 0 0 0 2px;
            border-color: #999999;
            width: 1px;
            height: 29px;
        }

    .payment_container {
        width: 45%;
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #ff4339;
        padding-top: 15px;
        margin-top: 15px;
        margin-left: 30%;
        color: #ff4339;
        cursor: pointer;
    }

    .payment_text {
        margin: 8px 0px 0px -50px;
        width: 110px;
        color: #7e7e7e;
        font-family: "font-regular";
        font-style: normal;
        /* // font-weight: 400; */
        font-size: 12px;
        line-height: 17px;
        color: #7e7e7e;
    }

    .payment_container div {
        background-color: #ffffff;
        font-weight: bold;
        border: 2px solid #ff4339;
        margin-top: -28px;
        width: 20px;
        height: 20px;
        line-height: 17px;
        text-align: center;
        border-radius: 50%;
    }

    .Mentorshiprate {
        margin: 141px;
        margin-top: 24px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 29px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #404253;
        margin-left: 221px;
    }

    .instructions_tittle {
        margin-top: -121px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #767a96;
        margin-left: 174px;
    }

    .mentorpeople {
        margin: 232px;
        margin-left: 231px;
        margin-top: -87px;
        position: absolute;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #767a96;
    }

    .midline {
        position: absolute;
        width: 246px;
        height: 0px;
        left: 646px;
        top: 793px;
        border: 1px solid #c4c4c4;
        transform: rotate(90deg);
    }

    .dollersign {
        position: absolute;
        /* width: 180px; */
        /* height: 25px; */
        /* left: 368.5px; */
        /* top: 350.5px; */
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 44.9995px;
        line-height: 54px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #404253;
        margin-left: 285px;
        /* margin-bottom: -65px; */
        margin-top: -122px;
    }

    .skipfornow {
        text-decoration-line: underline;
        margin-left: -15%;
        color: #ff4339;
        font-size: 15px;
        cursor: pointer;
    }

    .skip {
        font-size: 13px;
        padding-left: 13px;
    }

    .skipfornowtext {
        text-decoration-line: underline;
        color: #404253;
        font-size: 15px;
        text-align: center;
    }

    .Personaldetails {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #404253;
    }

    .bgcolors {
        background-color: #ff4339;
        color: whitesmoke;
    }
    /* .css-13cymwt-control{
  border-radius: 0px !important;
    border-width: 0px 0px 1px 0px !important;
}
.css-t3ipsp-control:hover{
  border-color: gray !important;
}
.css-t3ipsp-control{
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  box-shadow: 0 0 0 1px transparent !important;
} */
    .title_header {
        font-family: 'font-regular';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        /*  line-height: 130px; */
        display: flex;
        color: #404253;
        justify-content: center;
    }

    .sub_title1 {
        height: 29px;
        left: 61px;
        top: 114px;
        font-family: "font-regular";
        font-weight: 600;
        line-height: 0px;
        color: #404253;
        display: flex;
        justify-content: center;
    }

    .sub_title2 {
        left: 61px;
        top: 164px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #3e3e3e;
    }

    .under {
        position: absolute;
    }

    .over {
        position: absolute;
    }

    .capsule_header {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700 !important;
        font-size: 20px !important;
        line-height: 24px;
        color: #404253;
    }

    .capheader {
        font-size: 16px !important;
    }

    .capsule_title {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #767a96;
    }

    .nav-link-alt.active::before,
    .nav-link-alt:hover::before {
        bottom: -15px;
    }

    .bgcolors {
        background-color: #ff4339;
        color: whitesmoke;
    }
    /* .css-13cymwt-control{
  border-radius: 0px !important;
    border-width: 0px 0px 1px 0px !important;
}
.css-t3ipsp-control:hover{
  border-color: gray !important;
}
.css-t3ipsp-control{
  border-radius: 0px !important;
  border-width: 0px 0px 1px 0px !important;
  box-shadow: 0 0 0 1px transparent !important;
} */
    /* .title_header{
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 130px;
  display: flex;
  color: #404253;justify-content: center;
} */
    .sub_title1 {
        height: 29px;
        left: 61px;
        top: 114px;
        font-family: "font-regular";
        font-weight: 600;
        line-height: 0px;
        color: #404253;
        display: flex;
        justify-content: center;
    }

    .sub_title2 {
        left: 61px;
        top: 164px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: #3e3e3e;
    }

    .capsule_header {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 13.1747px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: #404253;
    }

    .capsule_title {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 9.88104px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #767a96;
    }

    .nav-link-alt.active::before,
    .nav-link-alt:hover::before {
        bottom: -15px;
    }

    /* Personal Detail Styling */

    input::placeholder {
        opacity: 0.5;
        color: #767a96 !important;
        font-family: "font-regular" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 15px !important;
        line-height: 19px !important;
        display: flex !important;
        align-items: center !important;
    }

    .form-control-customstyl {
        border-radius: 10px;
        border: 1px solid #c4c4c4;
    }

    .personaldetail-form {
        padding-top: 40px;
    }

    .form-label {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #404253;
    }

    .sub_title1_personaldtl {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        color: #404253;
    }

    .sub_title2_personaldtl {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #404253;
    }

    .mentorpayment {
        padding: 40px 0px 15px 0px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        text-align: center;
        color: #404253;
    }

    .setup-terms {
        color: #404253;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }

    /* Bank Account Detail Styling */

    /* Personal Detail Styling */
    .session-box {
        background-color: #e5e5e5;
        border-radius: 15px;
    }

    .sub_title_bankactdtl {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        color: #404253;
    }

    .skipfornowstyle {
        text-decoration-line: underline;
        color: #404253;
        font-size: 16px;
        text-align: center;
    }

    .yourmentorship {
        width: 310px;
        height: 170px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #404253;
        margin-left: 54px;
    }

    .preferdbanking {
        width: 614px;
        font-size: 13px;
        margin-left: 56px;
        margin-top: -67px;
        color: #404253;
    }

    .detailserchbar {
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        background-color: white;
        padding: 12px 20px 12px 40px;
    }

    .apple_img {
        width: 88px;
        height: 46px;
        margin-right: -21px;
        margin-left: -25px;
    }

    .gpay_img {
        width: 46px;
        height: 50px;
    }

    .meth_pay {
        width: 584px;
        font-size: 20px;
        margin-bottom: -45px;
    }

    .round_shape {
        cursor: pointer;
        position: absolute;
        width: 20px;
        height: 20px;
        clip-path: circle(46% at 50% 50%);
        border-radius: 72px;
        border: 3px solid rgba(0, 0, 0, 0.25);
    }

    .hide_line {
        border: none;
        border-color: transparent;
    }

        .hide_line::placeholder {
            margin-left: -55px;
            color: red;
        }

    .form-control.is-invalid:focus,
    .form-control.is-valid:focus {
        box-shadow: 0 0 0 0 rgb(220 53 69 / 25%) !important;
    }

    .form-control-border-btm {
        border-bottom: 1.78829px solid #a4a4a4;
        padding-left: 0.175rem;
    }

    .modal-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, 0%);
        line-height: 1.4;
        /* border-radius: 40px; */
        max-width: 600px;
        min-width: 300px;
    }

    .CSRModal {
        width: 350px;
        max-width: none !important;
    }

    .close-modal {
        position: absolute;
        top: 6px;
        right: 29px;
        padding: 5px 7px;
        border: none;
        background: red;
    }

    .uploadstyle {
        background-color: red;
    }

    .imagesection {
        text-align: center;
    }

    .box1 {
        text-align: center;
        color: #404253;
    }

    .mainsection {
        border: 2px dashed #404253;
        height: 150px;
        margin: 50px 150px;
    }

    .wrapper {
        background-color: #ffffff;
    }

    .header {
        height: 50px;
        color: white;
        background-color: red;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        display: flex;
        align-items: center;
        color: #f1f2f4;
    }

    .view_prfl {
        width: 80%;
        height: 40%;
        font-size: 12px;
    }

        .view_prfl.outline_button {
            border: 1px solid #ff4339;
            background: white;
            color: #ff4339;
        }

    .section-notf {
        height: 200px;
        background: lightgreen;
    }

    .fill_image {
        width: -webkit-fill-available;
    }

    input[type="file"] {
        display: none;
    }

    .profile-sectionjournal {
        background: #fffffe;
        /* border: 1px solid #C4C4C4; */
        box-shadow: 0px 4px 20px rgba(164, 164, 164, 0.25);
        border-radius: 20px;
    }

    .dollarcss {
        color: #28963c;
    }

    .view_join {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        /* height: 40%; */
        width: 100px;
        font-size: 12px;
    }

    .view_rescedule {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        /* height: 40%; */
        width: 150px;
        font-size: 12px;
    }

    .inactive_button {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        font-size: 10px;
    }

    .view_survey {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        /* height: 40%; */
        width: 150px;
        font-size: 12px;
    }

    .boxcss {
        border: 1px solid #ffffff;
        border-radius: 11.6111px;
        box-shadow: 0px 1.45139px 5.80556px rgba(64, 66, 83, 0.2), inset 0px 1.45139px 2.90278px rgba(64, 66, 83, 0.1);
    }

    .cornerimage {
        text-align: end;
    }

    .cornerImg {
        width: 40px;
        height: 40px;
    }

    .session_header {
        color: #101230;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
    }

    .subsession_header {
        color: #767a96;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 17.0278px;
    }

    .view-color {
        background-color: #ff4339;
        color: white;
    }

        .view-color:hover {
            background: white;
            border-color: #ff4339;
            color: #ff4339;
        }

    .dotcss {
        text-align: end;
    }

    .mentee_ineersection {
        background: #ffffff;
        box-shadow: 0px 1.58642px 6.34568px rgba(64, 66, 83, 0.2), inset 0px 1.58642px 3.17284px rgba(64, 66, 83, 0.1);
        border-radius: 12.6914px;
    }

    .mentee_headersection {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 43px;
        display: flex;
        align-items: center;
        color: #101230;
    }

    .mentee-para {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 19.037px;
        line-height: 23px;
        letter-spacing: 0.03em;
        color: #404253;
    }

    .mentee-headerr {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 31.7284px;
        line-height: 38px;
        display: flex;
        align-items: center;
        /* Subtle Text */

        color: #767a96;
    }

    .chat_img {
        position: absolute;
    }

    .box_line {
        margin-left: 106px;
        position: absolute;
        height: 170px;
        margin-top: -29px;
    }

    .chat_timing:before {
        content: "";
        display: block;
        width: 306px;
        height: 1px;
        background: #c4c4c4;
        position: absolute;
        margin-left: -311px;
        margin-top: 5px;
    }

    .chat_timing:after {
        content: "";
        width: 450px;
        height: 1px;
        background: #c4c4c4;
        position: absolute;
        margin-left: 6px;
        margin-top: 5px;
    }

    .msg_btn {
        color: #ff4339;
    }

    .small_icons {
        color: #ff4339;
        font-size: 23px;
    }

    .mike_img {
        position: absolute;
        margin-top: 11px;
        margin-left: 695px;
    }

    .connect_msg {
        height: 52.85px;
        left: 1%;
        top: calc(50% - 98.85px / 2 + 0.92px);
        background: #f1f2f4;
        border-radius: 34.2793px 34.2793px 34.2793px 0px;
    }

    .req_box {
        background: #e5e5e5;
        border-radius: 24.529px;
        margin-top: 0px;
    }

    .session_req {
        left: 0.82%;
        background: #f1f2f4;
        box-shadow: 0px 2.15806px 8.63226px rgba(64, 66, 83, 0.2), inset 0px 2.15806px 4.31613px rgba(64, 66, 83, 0.1);
        border-radius: 34.529px;
    }

    .hr_line {
        width: 954px;
        margin-left: -36px;
    }

    .input_msgbox {
        background: #f1f2f4;
        border-radius: 247.026px;
        border: none;
        color: black;
        padding-left: 10px;
    }

    .feed_mar {
        font-size: 17px;
    }

    .number_style {
        background-color: #ffffff;
        color: red;
        font-weight: bold;
        border: 2px solid #ff4339;
        margin-left: -30px;
        width: 26px;
        height: 26px;
        text-align: center;
        border-radius: 50%;
    }

    .feed-menteeheader {
        padding: 0px 20px 0px 0px;
    }

    .mentee_headerText {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        color: #404253;
    }

    .feed-mnentee_sub1 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.03em;
        color: #28963c;
    }

    .feed-mnentee_sub2 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.03em;
        color: #404253;
    }

    .card {
        border: 1px solid #c8cad5;
        border-radius: 20px;
    }

    .conformation_box {
        background: rgba(182, 242, 225, 0.72);
        border-radius: 20px;
    }

    .conformation_icon {
        font-size: 63px;
        margin-top: -61px;
        margin-left: 174px;
    }

    .cap_icon {
        width: 86px;
        margin-left: 164px;
    }

    .chat_secondheader {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #404253;
    }

    .conformation_img {
        position: absolute;
        margin-top: -111px;
        margin-left: 499px;
    }

    .chatheader_title {
        font-size: 27px;
        color: black;
    }

    .notification_box {
        height: 198px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 16px;
    }

    .reqsent_box {
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(64, 66, 83, 0.2), inset 0px 1px 2px rgba(64, 66, 83, 0.1);
        border-radius: 16px;
    }

    .view_mymentee_prfl {
        background-color: #ff4339;
        color: white;
        border-radius: 100px;
        border: 1px solid #ff4339;
        width: 150px;
        font-size: 15px;
    }

        .view_mymentee_prfl:hover {
            background: white;
            border-color: #ff4339;
            color: #ff4339;
        }

    .view_mymentee_desc {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        width: 150px;
        font-size: 15px;
        border-radius: 100px;
    }

        .view_mymentee_desc:hover {
            background-color: #ff4339;
            color: white;
        }

    .view_mymentee_notifications {
        background: #ff4339;
        border-color: #ff4339;
        border-radius: 40px;
        color: #ffffff;
        font-size: 22px;
        width: 100%;
        /* line-height: 25px; */
    }

        .view_mymentee_notifications:hover {
            background: white;
            border-color: #ff4339;
            color: #ff4339;
            width: 100%;
        }

    .view_mymentee_share {
        border: 1px solid #ff4339;
        background: white;
        color: #ff4339;
        border-radius: 100px;
        font-size: 22px;
        width: 100%;
        /* line-height: 25px; */
    }

        .view_mymentee_share:hover {
            background-color: #ff4339;
            color: white;
        }

    .chat_tittle {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        /* line-height: 150%; */
        text-align: center;
        color: #404253;
    }

    .profile-capsul:hover {
        background-color: #ff4339;
        color: white;
    }

    .calendar_slot {
        width: 800px;
        background: #ffffff;
        border: 1px solid #c4c4c4;
        border-radius: 20px;
    }

    .slot_timimg {
        background: #ffffff;
        box-shadow: 0px 0.535714px 2.14286px rgba(64, 66, 83, 0.2), inset 0px 0.535714px 1.07143px rgba(64, 66, 83, 0.1);
        border-radius: 8.57143px;
        font-family: "font-regular";
        color: #404253;
    }

    .underlinelink {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 17px;
        text-align: center;
        text-decoration-line: underline;
        color: #ff4339;
    }

    .calendarslideicon {
        position: absolute;
        margin-left: -44px;
        margin-top: 208px;
    }

    .icon_size {
        position: absolute;
        font-size: 30px;
    }

    .sendreq_confirmation {
        background: #ffffff;
        border: 5px solid #b6f2e1;
        border-radius: 20px;
    }

    .Confirmationdilouge_box {
        width: 687px;
        background: #ffffff;
        border-radius: 20px;
    }

    .text_overflowview {
        display: inline-block;
        width: 180px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .feed_text_sub1 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #333333;
    }

    .feed_text_sub2 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }

    .feed_sign {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }

    .checkinpage_tittle2 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #333333;
    }

    .checkbox_contanier {
        background: #ffffff;
        box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .css-13cymwt-control {
        border-radius: 40px !important;
    }

    .css-1u9des2-indicatorSeparator {
        width: 0px !important;
    }

    .arrow_down {
        position: absolute;
        margin-top: 169px;
        margin-left: -135px;
        width: 20px;
    }

    .arrow_up {
        margin-top: -188px;
        margin-left: -121px;
        width: 13px;
    }

    .Mentorshiptittle {
        position: absolute;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        /* display: flex; */
        align-items: center;
        text-align: center;
        color: #404253;
    }

    .calender_tittle {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #404253;
    }

    .Recurrence_box {
        box-sizing: border-box;
        width: 36.09px;
        height: 30px;
        left: 607.26px;
        top: calc(50% - 30px / 2 + 371.4px);
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background: #fffffe;
        box-shadow: 0px 4px 20px rgba(164, 164, 164, 0.25);
        margin-top: -5px;
    }

    .rmdp-day-picker {
        /* width: 255px; */
        /* //justify-content: center; */
    }

    .linestyle {
        width: 94%;
    }

    .infinite-scroll-component {
        overflow-x: hidden !important;
    }

    .star_rating {
        display: flex;
    }

    .chat_header {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        /* line-height: 130px; */
        display: flex;
        /* align-items: center; */
        color: #f1f2f4;
    }

    .imgupload {
        width: 336px;
        height: 336px;
        background: #ffffff;
    }

    .imgupload_box {
        margin-left: 29px;
        margin-top: -10px;
    }

    .btn-close {
        color: white;
    }

    .mentors:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .text_color {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        color: #4f4f4f;
        font-size: 18px;
        letter-spacing: -0.2px;
    }

    .logintext_color {
        font-family: "font-regular";
        font-style: normal;
        color: #4f4f4f;
        font-size: 18px;
        letter-spacing: -0.2px;
        margin-left: 166px;
    }

    .managereq:hover {
        box-shadow: rgba(4, 5, 4, 0.35) 0px 0px 6px;
        cursor: pointer;
        /* width: 1132px;
  margin-left: -9px;
  border-radius: 36.2459px; */
    }

    .mymentors:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 5px;
        cursor: pointer;
    }

    .posttittle {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        align-items: center;
        text-align: center;
        color: #ff4339;
    }

    .postbox {
        box-sizing: border-box;
        width: -webkit-fill-available;
        padding-left: 10px;
        border: 1px solid #404253;
        border-radius: 16px;
    }

    .postdilouge_box {
        background: #ffffff;
        box-shadow: 0px -4px 10px 2px rgba(200, 202, 213, 0.08);
        border-radius: 16px 16px 16px 16px;
    }
    /* .btn-close{
  position: absolute;
} */
    .postModal {
        width: 500px;
    }

        .postModal > .modal-content {
            width: 120%;
        }

    .availabilityModal > .modal-content {
        width: 602%;
        max-width: 832px;
        min-width: 300px;
        background: #ffffff;
    }

    .confirmationmodal > .modal-content {
        width: 502%;
        max-width: 400px;
        min-width: 300px;
        background: #ffffff;
    }

    .confirmationmodal > .modal-header {
        border-bottom: transparent;
        padding: 20px 20px 0px 0px;
    }

    .active_month {
        color: red;
        text-decoration: underline;
    }

    .modal-80w {
        min-width: 80%;
    }

    .modelboxheader_1 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 33px;
        /*   position: absolute; */
        color: #404253;
        margin-top: 95px;
    }

    .home_main_imag {
        width: 76px;
        height: 76px;
        border-radius: 50px;
    }

    .preview {
        width: -webkit-fill-available;
    }

        .preview > img {
            width: 100%;
        }

        .preview > video {
            width: 100%;
            object-fit: contain;
        }

    .close {
        padding: 10px 15px;
        border-radius: 999px;
        outline: none;
        border: none;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .reportmodel {
        background: #ffffff;
        border: 0.610526px solid #767a96;
        border-radius: 12.2105px;
        width: 5%;
        max-width: none !important;
    }

        .reportmodel > .modal-content {
            max-width: 200px !important;
            min-width: 200px !important;
        }

    .reportbox_tittle1 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        color: #404253;
    }

    .reportModal {
        width: 420px;
        background: #ffffff;
        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 0px 0px;
    }

    .reporttittle2 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        color: #404253;
        font-size: 17px;
    }

    .btn-largesize {
        width: 342px;
        font-size: 17px;
    }

    .reporticons {
        font-size: 25px;
        color: #767a96;
    }

    .reportalertpopup {
        background: #ffffff;
        border: 0.610526px solid #767a96;
        border: 0.610526px solid #767a96;
        border-radius: 12.2105px;
    }

    .btn-facebook:hover {
        background-color: #4267b2;
        filter: brightness(90%);
    }

    .btn-linkdein:hover {
        background-color: #4267b2;
        filter: brightness(90%);
    }

    .policy_style {
        letter-spacing: 0.8px;
        color: #4958e0;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
    }

    .cemraimg {
        height: 577px;
        width: 205vh;
        margin-left: -117px;
    }

    .arrowbtn {
        position: absolute;
        /* margin-top: 141px; */
        margin-top: 30px;
        font-size: 45px;
    }

    .vediocll_img {
        position: relative;
        margin-left: 309px;
        border-radius: 12.2105px;
        width: 145px;
    }

    .vediocll_selfimg {
        position: absolute;
        /* margin-bottom: 52px; */
        margin-top: -331px;
        margin-left: 897px;
        width: 216px;
        height: 185px;
    }

    .home_events {
        border-right: 1px solid #c4c4c4;
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
    }

    .commentbox {
        width: 491px;
        left: 1%;
        top: calc(50% - 98.85px / 2 + 0.92px);
        background: #f1f2f4;
        border-radius: 19px 19px 19px 19px;
    }

    .commentdate {
        margin-left: 61px;
        margin-top: -12px;
    }

    .cmtimg {
        width: 40px;
        height: 40px;
        float: left;
    }

    .cmtoverflow {
        overflow-y: scroll;
        scroll-behavior: smooth;
        height: 465px;
    }

    .sendmsgbtn {
        transform: rotate(40deg);
        background: none;
        border: none;
        margin-left: -13px;
    }

    .dilougeboxwidth {
        width: 629px;
    }

    .logintittle {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 42.2px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.8px;
        color: #101230;
        margin-left: 228px;
    }

    .lgnbtn {
        width: 300px;
        height: 57px;
        left: 544px;
        top: 602px;
        background: #ff4339;
        border-radius: 50px;
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        border-radius: 50px;
    }

    .designationoverflow {
        display: inline-block;
        /* width: 180px; */
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .postpopup {
        width: 262px;
        position: absolute;
        margin-left: -207px;
        margin-top: -13px;
        background: #ffffff;
        border: 0.610526px solid #767a96;
        border: 0.610526px solid #767a96;
        border-radius: 12.2105px;
        cursor: pointer;
    }

    .alets {
        background-color: #ffffff;
        /* border: 1px solid #767A96; */
        /* border-radius: 15px; */
        /* width: 25rem; */
        margin: 0px -16px;
        padding: 10px;
    }

        .alets:hover {
            background-color: rgba(0, 0, 0, 0.09);
        }

    .matchtabbtn {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 31.2747px;
        /* line-height: 150%; */
        text-align: center;
    }

    .availabltyheader {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* text-align: center; */
        color: #ff4339;
        margin-left: -225px;
    }

    .sharebtn {
        width: 32%;
        margin-left: 85px;
    }

    .navlogo {
        height: 44px !important;
        margin-left: 45px;
    }

    .schedulebox {
        width: 766px;
        height: 111px;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(64, 66, 83, 0.2), inset 0px 1px 2px rgba(64, 66, 83, 0.1);
        border-radius: 16px;
    }

    .availabiltydate {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 42px;
        color: #101230;
        margin-left: 11px;
    }

    .availabiltytime {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #404253;
        margin-left: 13px;
    }

    .text_overflowview .tooltiptext {
        visibility: hidden;
        width: 331px;
        background-color: whitesmoke;
        color: black;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        margin-top: 18px;
        border: 1px solid black;
    }

    .text_overflowview:hover .tooltiptext {
        visibility: visible;
    }

    .sectittle {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 37px;
        letter-spacing: 0.38px;
        color: #000000;
        margin-left: 87px;
    }

    .cllbtn {
        width: 131px;
        height: 35px;
    }

    .sectime {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: #000000;
    }

    .sectittle2 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 37px;
        letter-spacing: 0.38px;
        color: #000000;
        margin-left: 87px;
    }

    .sectittle3 {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
    }

    .secline {
        height: 97px;
        width: 6px;
        color: #4958e0;
    }

        .secline:hover {
            color: #f3cd47;
        }

    .seclinelg {
        margin-top: -62px;
        height: 122px;
    }

    .form-control-customcss {
        border: none;
    }

    .PhoneInput {
        padding: 0px 0px 0px 3px;
        padding-left: 0px;
    }

    .maindiv {
        display: flex;
    }

    .firstdiv {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #404253;
    }

    .PhoneInputInput {
        border: none;
    }

        .PhoneInputInput:focus {
            border: none;
        }

        .PhoneInputInput:focus-visible {
            outline: none;
        }

    .maindiv {
        padding: 8px 5px 8px 5px;
    }

    .PhoneInputCountry {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #404253;
    }

    .dropdownn {
        background: #fff;
        /* box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);  */
        color: #333;
        font-weight: 500;
        /* width: 25%;  */
        overflow-y: auto;
        max-height: 100px;
        padding: 0.5rem 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        position: absolute;
        z-index: 1000;
    }

    .dropdown-menuu .dropdown-itemm {
        padding: 4px 0px 1px 4px;
        cursor: pointer;
        transition: all 0.2s;
        padding: 0.25rem 1.2rem;
    }

        .dropdown-menuu .dropdown-itemm:hover {
            background-color: #f8f9fa;
        }

    .decNum,
    .incNum {
        background: none;
    }

    li.tab_inactive,
    li.tab_disabled {
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 4px 20px hsla(0, 0%, 64%, 0.25);
        cursor: pointer;
        height: 47px;
        padding: 0;
        line-height: 47px;
        margin: 8px;
    }

        li.tab_inactive:last-child:before,
        li.tab_disabled:last-child:before {
            border-left-color: transparent !important;
        }

    .horizontal-progress li.active {
        color: #ff4339;
    }

        .horizontal-progress li.active:before {
            border-color: #ff4339;
        }

        .horizontal-progress li.active + li:after {
            background-color: none !important;
        }

    .horizontal-progress li:after,
    .vertical-progress li.active:after {
        content: "";
        display: inline-block;
        height: 1px;
        left: 50%;
        position: absolute;
        width: 100%;
        top: 15px;
        z-index: 9;
        background-color: #cccccc;
        margin-left: 3px;
    }

    .horizontal-progress li:before {
        z-index: 10;
        position: relative;
    }

    .horizontal-progress > li:last-child:after {
        background-color: transparent !important;
    }

    .horizontal-progress > li:last-child::before {
        border-left-color: #ccc !important;
    }

    .custom-calendar {
        /* width: 462px !important; */
        border: none !important;
    }

    .react-calendar__navigation__next2-button {
        visibility: hidden;
    }

    .react-calendar__navigation__prev2-button {
        visibility: hidden;
    }

    .react-calendar__navigation__prev-button {
        position: absolute !important;
        margin-left: 343px !important;
        margin-top: 13px;
        /* width: 58px; */
        /* height: 4px; */
        font-size: 36px;
        color: red;
        background-color: white !important;
    }

    .react-calendar__navigation__next-button {
        font-size: 36px;
        color: red;
        position: absolute !important;
        /* margin-left: -14px; */
        margin-left: 411px !important;
        background-color: white !important;
    }

    .react-calendar__navigation__label__labelText--from {
        color: red;
        font-size: 22px;
        margin-left: -214px;
        margin-top: -21px;
        position: absolute;
    }

    .availline {
        margin-top: 35px;
        position: absolute;
        width: 449px;
        margin-left: 9px;
    }

    .rmdp-header-values {
        margin-left: 0px;
        color: #ff4339;
        margin: auto;
        font-size: 20px;
    }

    .rmdp-input {
        margin-left: 73px !important;
        margin-top: 13px !important;
        height: 36px !important;
    }

    .fc .fc-col-header-cell-cushion {
        color: #999999 !important;
    }

    .fc .fc-button-primary:not(:disabled).fc-button-active,
    .fc .fc-button-primary:not(:disabled):active,
    .fc .fc-button-primary:hover {
        background-color: #ff4339;
        border-color: #cf190f;
    }

    .fc .fc-button-primary {
        background-color: #ff4339c2;
        border-color: #ff433980;
    }

    .fc .fc-toolbar-title {
        color: #ff4339;
    }

    .datescrollbar {
        margin-left: 167px;
        margin-top: -39px;
        width: 20px;
    }

    .schtittle {
        font-family: "font-regular";
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: 0.03em;
        color: #ff4339;
    }

    .scdbox {
        width: 1149px;
        margin-left: -16px;
    }

    .schimg {
        font-size: 32px;
        color: red;
        /* margin-top: -52px; */
        position: absolute;
        margin-top: 23px;
        margin-left: 35px;
    }

    .datetranfor {
        transform: rotate(87deg);
        margin-left: 1px;
    }

    .scdtittle2 {
        margin-left: 423px;
        margin-top: -38px;
        font-family: "font-regular";
        font-weight: 600;
        font-size: 28px;
        line-height: 26px;
        letter-spacing: 0.03em;
        color: black;
    }

    .schedulebox2 {
        width: 1035px;
        background: #f1f2f4;
        border: none;
    }

    /* ////////// */
    .custom-vlist {
        margin: 16px 0px;
    }

        .custom-vlist > li {
            border-radius: 20px;
            background: #fffffe;
            box-shadow: 0px 4px 20px 0px rgba(164, 164, 164, 0.25);
            margin-bottom: 24px;
            position: relative;
        }

            .custom-vlist > li > p,
            .custom-vlist > li > span {
                text-decoration: none;
                display: block;
                padding: 16px 16px 16px 64px;
                color: #404253;
                font-family: font-regular;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .custom-vlist > li:before {
                position: absolute;
                left: 23px;
                top: 12px;
                content: "";
                /* // background: url(../public/assets/images/plus.svg) no-repeat #ffffff; */
                background: url(../public/assets/images/svgimges/gray_inactive.svg) no-repeat #ffffff;
                background-size: cover !important;
                height: 26px;
                width: 26px;
                z-index: 9;
            }

            .custom-vlist > li:after {
                position: absolute;
                left: 35px;
                top: 12px;
                content: "";
                background: #c8cad5;
                background-size: cover;
                height: 150%;
                width: 2px;
                z-index: 8;
            }

            .custom-vlist > li:last-child::after {
                display: none;
            }

            .custom-vlist > li.active:before {
                /* background: url(../public/assets/images/plus_grey.svg) no-repeat #ffffff; */
                background: url(../public/assets/images/svgimges/green_active.svg) no-repeat #ffffff;
            }

            .custom-vlist > li.active:after,
            .custom-vlist > li.completed:after {
                background: #c4c4c4;
            }

            .custom-vlist > li.completed:before {
                /* background: url(../public/assets/images/check.svg) no-repeat #ffffff; */
                background: url(../public/assets/images/svgimges/green_complete.svg) no-repeat #ffffff;
            }

    .custom-hlist > li {
        position: relative;
        margin: 16px !important;
        width: 100px;
        text-align: center;
        vertical-align: top;
    }

        .custom-hlist > li > p,
        .custom-hlist > li > span {
            text-decoration: none;
            color: #7e7e7e;
            font-family: 'font-regular';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            position: relative;
            z-index: 2;
            margin: 55px 0px 0px 20px;
        }

        .custom-hlist > li.p:before {
            color: red;
        }

        .custom-hlist > li:before {
            position: absolute;
            left: 40px;
            top: 0px;
            content: "";
            /* // background: url(../public/assets/images/plus.svg) no-repeat #ffffff; */
            background: url(../public/assets/images/svgimges/waiting.svg) no-repeat #ffffff;
            background-size: cover !important;
            height: 50px;
            width: 50px;
            z-index: 9;
        }

        .custom-hlist > li:after {
            position: absolute;
            left: 80%;
            top: 24px;
            content: "";
            background: #ff4339;
            background-size: cover;
            height: 2px;
            width: 100%;
            z-index: 1;
        }

        .custom-hlist > li:last-child::after {
            display: none;
        }

        .custom-hlist > li.completed:before {
            /* background: url(../public/assets/images/check.svg) no-repeat #ffffff; */
            background: url(../public/assets/images/svgimges/processing.svg) no-repeat #ffffff;
        }

        .custom-hlist > li.active:after {
            background: #c4c4c4;
        }

        .custom-hlist > li.completed:after {
            background-color: #ff4339;
        }

    .inputboxborder {
        border-radius: 10px;
        border: 1px solid #c4c4c4;
    }

    .shortmsgbox {
        border: 1px solid;
        height: 87px;
        border-radius: 8px;
    }

    .css-13cymwt-control {
        border-radius: 4px !important;
        border: 1px solid #c4c4c4 !important;
    }

    .rmdp-week {
        width: 393px;
    }

    .signupbtn {
        padding: 14px 0px;
        width: 18%;
    }

    .linkedin_background {
        background-image: url("../public/assets/images/BackgroundImage.png");
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
    }

    .linkedin_header {
        background-color: #ff4339;
        height: 60px;
        width: 103%;
        display: flex;
        vertical-align: top;
    }

    .linkedin_img {
        object-fit: contain;
        margin-left: 9%;
        width: 2.5%;
    }

    .linkedin_header_word {
        margin-top: 0.8%;
        margin-left: 1%;
    }

    .text-danger {
        color: #dc3545 !important;
    }

    .linkedIn {
        margin-top: 35vh !important;
    }

    .cccccc {
        background-color: transparent;
        color: #f1f2f4;
        border: transparent !important;
    }

    .btn:focus {
        box-shadow: 0 0 0 0.25rem transparent;
    }

    .sidebar1 {
        margin-left: -103px;
        width: 225px;
        height: 575px;
    }

    .sidebar2 {
        margin-left: 122px !important;
        /* position: absolute; */
        /* margin-left: 131px; */
        width: 223px !important;
        height: 547px !important;
    }

    .inputupload {
        border-radius: 10px;
        border-width: 1px;
    }

    .feed-width {
        width: 803px;
        margin-left: -138px;
    }

    .infinite-scroll-component__outerdiv {
        /* width: 822px;
    margin-left: -149px; */
    }

    #videos {
        position: relative;
        height: 85vh;
        margin: auto;
        align-self: flex-start;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
        justify-items: center;
        align-items: center;
    }

    .vid {
        position: relative;
        background-color: black;
        height: 95%;
        width: 95%;
        border-width: 1px;
        border-color: #38373a;
        border-style: solid;
    }

    .agora_controls {
        background: transparent;
        border-radius: 5px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        /* margin: -20px auto; */
        display: grid;
        grid-template-columns: repeat(3, 33%);
        align-items: center;
        justify-items: center;
        z-index: 999999999999999;
        width: 500px;
        max-width: 60vw;
    }

        .agora_controls p {
            padding: 10px;
            cursor: pointer;
            background: transparent;
            color: #f7f7f7;
            border-width: 1px;
            border-color: #f7f7f7;
            border-style: solid;
            border-radius: 50%;
        }

    .css-3w2yfm-ValueContainer {
        font-size: 18px;
    }
    /* .fc-scrollgrid-section-header,.presentation, .fc .fc-timegrid-divider,.fc-scrollgrid-sync-table,.fc .fc-toolbar.fc-header-toolbar  {
  display: none;
}*/
    .positionseting {
        position: absolute;
        margin-left: 393px;
    }

    .fc .fc-timegrid-divider,
    .presentation,
    .fc-scrollgrid-sync-table,
    .fc .fc-toolbar-title,
    .fc-button-primary {
        display: none;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 10px 4px;
    }

    .fc .fc-timegrid-slot-minor {
        border-top-style: none;
    }

    .Kalend__Calendar__table {
        height: 60vh !important;
    }

    .eventimage {
        width: 100px;
    }

    .Kalend__CalendarHeaderDates__circle {
        pointer-events: none;
    }

    .Kalend__ButtonBase-border,
    .Kalend__header_calendar_buttons__container,
    .Kalend__HeaderCalendarTitle {
        display: none !important;
    }

    .Kalend__CalendarHeader-tall {
        box-shadow: 0 5px 5px -10px #333 !important;
    }

    .Kalend__Calendar__table,
    .Kalend__Calendar__root {
        border: 1px solid #8080801f;
    }

    .Kalend__CalendarBodyHours__line {
        background: #e0e0e047 !important;
    }

    .Kalend__CalendarBodyHours__text {
        color: #c4c4c4 !important;
    }

    .modal-90w {
        /* width: 90;
max-width: none!important; */
    }

    .modal.fade,
    .modal-dialog {
        /* transform: translate(-100px, -100px)  !important; */
    }

    .pn-msg-list {
        height: 50%;
    }

    .signbtnwidth {
        border-radius: 50px;
        width: 165px;
        height: 60px;
    }

    .dateline {
        margin-top: 11px;
        width: 288px;
        margin-left: 78px;
    }

    .inputrecurncen {
        width: 367px;
        margin-left: 51px;
    }

    .modal-90w .modal-content {
        margin-left: -158px;
        margin-top: -127px;
        width: 495px;
    }

    .radio_sizebox {
        width: 24.09px !important;
        height: 24px !important;
    }

    .dateupadte .rmdp-day-picker {
        margin-left: 26px;
        margin-top: 15px;
    }

    .dateupadte .rmdp-header-values {
        margin-left: 26px;
    }

    .dateupadte .rmdp-header {
        margin-top: -7px;
    }

    .roleheader {
        font-family: "font-regular";
        font-weight: 700;
        font-size: 36px;
        line-height: 43.2px;
        letter-spacing: 0.03em;
        color: black;
        margin-top: 83px;
    }

    .roleheader1 {
        font-family: "font-regular";
        font-weight: 600;
        font-size: 24px;
        line-height: 43.2px;
        letter-spacing: 0.03em;
        color: black;
    }

    .mentheader1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #000000;
        margin-top: 20px;
    }

    .mentdescription {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #000000;
    }

    .roleimg {
        width: 160px;
        height: 160px;
    }

    .roleimgpay {
        width: 296px;
        height: 305px;
    }

    .boxdegin {
        width: 192px;
        height: 226px;
        background: #ffffff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 24px;
        display: block;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
    }

    .mentdegin {
        width: 100%;
        height: 594px;
        border-radius: 30px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        display: block;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        margin-bottom: 20px;
    }

        .mentdegin > img {
            width: 100%;
        }

    .btnwcss {
        width: 208px;
        height: 50px;
    }

    .title_exp {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        /*  line-height: 130px; */
        display: flex;
        margin-top: -16px;
        color: #404253;
    }

    .summary .col-5 {
        background: #ffffff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 24px;
        display: block;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
    }

    .aviltycalender .rmdp-week {
        width: 382px;
        margin-top: 22px;
    }

    .calenderfontsize {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #404253 !important;
    }

    .inputboxwidth {
        width: 304px;
    }

    .onbodingtitle {
        font-family: "font-regular";
        position: absolute;
        font-weight: 600;
        font-size: 21px;
        margin-top: -24px;
        color: #404253;
    }

    .react-ios-time-picker-input {
        margin-left: 152px;
        margin-top: -25px;
        position: absolute;
        width: 95px;
        height: 36px;
    }

    .react-ios-time-picker-popup {
        margin-left: 967px;
        margin-top: -750px;
    }

    .react-ios-time-picker-popup {
        position: fixed;
        top: -4px;
        bottom: 280px !important;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 99998;
    }

    .react-ios-time-picker-container {
        width: 115px;
    }

    .select {
        font-family: "font-regular";
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
    }

    select:focus {
        outline: none;
    }

    .recurrencedrop {
        font-family: "font-regular";
        width: 162px;
        height: 36px;
        position: absolute;
        margin-left: 149px;
        margin-top: -35px;
        border-radius: 4px;
        padding: 7px;
    }

    .gmtlist {
        position: absolute;
        margin-left: 153px;
        margin-top: -26px;
    }

    .react-ios-time-picker-container {
        background: white !important;
        width: 127px !important;
    }

    .react-ios-time-picker-btn-container {
        background-color: white !important;
    }
    /* .custom-vlist >li{
    height: 34px !important;
} */
    .custom-vlist > li.completed:before {
        background-color: #17be3c !important;
        border-radius: 30px !important;
    }

    .custom-vlist > li.active:after,
    .custom-vlist > li.completed:after {
        background-color: #17be3c !important;
    }

    /* Style the header */
    user_header {
        text-align: center;
    }

    /* Create two columns/boxes that floats next to each other */
    user_nav {
        float: left;
        width: 30%;
        padding: 20px;
    }

        /* Style the list inside the menu */
        user_nav ul {
            list-style-type: none;
            padding: 0;
        }

    user_article {
        float: left;
        padding: 20px;
        width: 70%;
    }

    /* Clear floats after the columns */
    user_section::after {
        content: "";
        display: table;
        clear: both;
    }

    .title_expes {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 700;
        position: absolute;
        font-size: 36px;
        margin-top: -79px;
        margin-left: 269px;
        color: #404253;
    }

    .sign-tiless {
        width: 349px;
        height: 202px;
        margin-left: 222px;
        margin-top: -104px;
        background: #ffffff;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 24px;
        display: block;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
    }

    .title_pay {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #404253;
    }

    .mentor-profile-upload-modal .modal-content {
        width: 336px !important;
    }

    .imgup .modal-content {
        width: 336px !important;
    }

    .imgup1 .modal-content {
        width: 336px !important;
        height: 500px;
    }

    .imgup .modal-header {
        border: none !important;
    }

    .mst_titlerow {
        font-size: 20px;
        text-align: center;
        display: flex;
        position: absolute;
        left: 19%;
        color: #404253;
    }

    .payment_box {
        width: 806px;
        height: 488px;
        border-radius: 24px;
        /* color: #FFFFFF; */
        background: #ffffff;
        border: 1px solid #c8cad5;
        margin-top: 70px;
        margin-left: 47px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .metorshipimg {
        width: 335px;
        float: right;
        height: 336px;
        border-radius: 24px;
        border: none;
        box-shadow: 0px 12px 0px rgb(0 0 0 / -9%);
        cursor: pointer;
    }

    .text_4 {
        color: rgb(0, 71, 255);
        margin-top: -16px;
        margin-left: -50px;
    }

    .comonboarding {
        width: 262px;
        height: 183px;
        margin-left: 498px;
        margin-top: 60px;
    }

    .commonbodrintitle {
        position: absolute;
        left: 35%;
        /* top: -56%; */
        /* top: 0px; */
        margin-top: 49px;
    }

    .dateupadteModal .modal-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, 0%);
        line-height: 1.4;
        background: #f1f1f1;
        /* border-radius: 40px; */
        max-width: 381px !important;
        min-width: 183px !important;
        height: 413px;
        border-radius: 24px 24px 24px 24px !important;
    }

    .cardholdercountry .css-13cymwt-control {
        /* border-radius: 0px !important; */
        /* border: 0px !important; */
        border-style: none !important;
        border-width: 0px !important;
        border-bottom: 1.78829px solid #a4a4a4 !important;
        border-radius: 1px !important;
        width: 128px !important;
    }

    .HandlerTimezone {
        position: absolute;
        width: 162px;
    }

        .HandlerTimezone .css-13cymwt-control {
            border-style: none !important;
            border-width: 0px !important;
            border-bottom: 1.78829px solid #a4a4a4 !important;
            border-radius: 1px !important;
            width: 141px !important;
        }

    .imgup1 .modal-header {
        border: none !important;
    }

    #form-file-upload {
        height: 16rem;
        width: 28rem;
        max-width: 100%;
        text-align: center;
        position: relative;
    }

    #label-file-upload {
        width: 250px;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 50%;
        border-style: dashed;
        border-color: #000000ad;
        background-color: #f8fafc;
    }

    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .profile_upload {
        margin-top: 65px;
        width: 240px;
        height: 240px;
        border-radius: 50%;
    }

    .probtn {
        width: 150px !important;
        height: 50px !important;
    }

    .form-control {
        font-family: "Myriad Pro" !important;
    }

    .timeinput {
        border: 1px solid;
        height: 33px;
        text-align: center;
        border-radius: 4px;
        width: 95px;
    }

    .btnshift {
        display: flex;
        position: absolute;
    }

    .imgsec {
        position: absolute;
        margin-left: 84px;
        margin-top: 6px;
    }

    .reviewtitle {
        font-family: "font-regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        /*   line-height: 25px; */
        display: flex;
        align-items: center;
        color: #767a96;
    }

    .pencss {
        margin-left: -63px;
        margin-top: 12px;
    }

    .arrow_key {
        position: absolute;
        margin-left: 88px;
        margin-top: -12px;
        width: 18px;
        cursor: pointer;
    }

    .tittleacount {
        position: absolute;
        margin-top: -360px;
        margin-left: 447px;
    }

    .custom-select {
        position: relative;
        font-family: Arial;
    }

        .custom-select select {
            display: none; /*hide original SELECT element:*/
        }

    .select-selected {
        background-color: DodgerBlue;
    }

        /*style the arrow inside the select element:*/
        .select-selected:after {
            position: absolute;
            content: "";
            top: 14px;
            right: 10px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #fff transparent transparent transparent;
        }

        /*point the arrow upwards when the select box is open (active):*/
        .select-selected.select-arrow-active:after {
            border-color: transparent transparent #fff transparent;
            top: 7px;
        }

    /*style the items (options), including the selected item:*/
    .select-items div,
    .select-selected {
        color: #ffffff;
        padding: 8px 16px;
        border: 1px solid transparent;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
        cursor: pointer;
        user-select: none;
    }

    /*style items (options):*/
    .select-items {
        position: absolute;
        background-color: DodgerBlue;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
    }

    /*hide the items when the select box is closed:*/
    .select-hide {
        display: none;
    }

    .select-items div:hover,
    .same-as-selected {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .timepostpopup {
        width: 262px;
        position: absolute;
        background: #ffffff;
        border: 0.610526px solid #767a96;
        border: 0.610526px solid #767a96;
        border-radius: 12.2105px;
        cursor: pointer;
        margin-left: 152px;
        /* margin-top: 11px; */
    }

        .timepostpopup .rmdp-shadow {
            margin-left: -3px !important;
        }
    /* imp css for active hover on time zone */
    .css-qbdosj-Input {
        visibility: hidden !important;
    }

    .active_role {
        border: 2px solid #ff4339;
        background: #f1f2f4;
    }

    .durationdrop {
        background-color: white;
        border: 1px solid #c4c4c4 !important;
        color: black;
        width: 162px !important;
        border-radius: 4px;
    }

    .recurrencedrop .btn:hover {
        background-color: white !important;
        color: black !important;
        box-shadow: none !important;
    }

    .dropdown-toggle::after {
        position: absolute;
        right: 8px;
        top: 23px;
    }

    .popupheader {
        background-color: #f1f2f4;
        width: 260px;
        margin-left: -16px;
        height: 39px;
        margin-top: -16px;
        border-radius: 10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

    .slider {
        position: absolute;
        width: 61px;
        /* margin-top: 0px; */
        height: 32px;
        text-align: center;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 10px;
    }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

    input:checked + .slider {
        background-color: #e5e5ea;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

        .slider.round:before {
            border-radius: 50%;
        }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .timeduration {
        position: absolute;
        margin-left: 38px;
        margin-top: 14px;
    }

    .shadw {
        box-shadow: 4px 0px 0px rgba(0, 0, 0, 0.25);
    }

    .center_div {
        margin: auto;
        width: auto;
        background: #feffff;
        border: 1px solid #c4c4c4;
        padding: 10px;
    }
.widthsize {
    width: 790px;
}
@media (min-width:321px) and (max-width:768px) {
    .widthsize {
        width:320px;
    }
}

    .choz_meshp {
        width: 350px;
        height: 453px;
        background: #ffffff;
        /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border-radius: 15px;
        padding: 24px;
    }

    .sign-sbuscrp {
        background: #feffff;
        border: 1px solid #c4c4c4;
        position: relative;
        width: 836px;
        height: auto;
    }

    .choz_meshp_title {
        font-size: 30px;
        color: rgba(16, 18, 48, 1);
    }

    .choz_meshp_title2 {
        font-size: 20px;
        color: rgba(16, 18, 48, 1);
    }

    .choz_button {
        height: 45px;
        width: 200px;
        font-size: 20px;
        border-radius: 20px;
        background: #ff4339;
        color: #ffffff;
        height: 40px;
        border: 0;
    }

    .socilabutton_css {
        font-size: 21px;
        font-weight: 500;
        height: 48px;
        width: 187px;
        /* margin-left:100px */
    }

    .title_1 {
        color: rgba(16, 18, 48, 1);
        font-size: 18px;
    }

    .title_2 {
        color: rgba(64, 66, 83, 1);
        font-size: 14px;
    }

    .email_error {
        color: red;
    }

    .v_title1 {
        text-align: center;
        font-size: 18px;
        color: rgba(16, 18, 48, 1);
    }

    .v_title2 {
        text-align: center;
        font-size: 15px;
        color: rgb(64 66 83 / 58%);
    }

    .drdwn-vsblty .css-qbdosj-Input {
        visibility: visible !important;
    }

    .drdwn-vsblty .css-13cymwt-control,
    .css-t3ipsp-control {
        flex-direction: row-reverse;
    }

    .verify_email_modal {
        width: 300px;
    }

        .verify_email_modal > .modal-content {
            width: 120%;
        }
{

}
#myTab >li >.nav-link {
    padding: 0px;
    color:black;
}
    #myTab > li > .nav-link.active {
        color: red;
    }
.activelable{
    color:red;
}
.nav-tabs {
    align-items: center;
    justify-content: center;
}